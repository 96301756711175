import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import pluralize from 'pluralize';
import kebabCase from 'lodash/fp/kebabCase';
import BaseTile from '../BaseTile/BaseTile';
import Link from '../../atoms/Link/Link';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';
import { truncateTextByWords } from '../../../utils/textUtils';

const wrapperCss = css`
  @media (${breakpoints.mobile}) {
    width: 100%;
  }
`;

const customCss = css`
  .title {
    margin: 0;
    color: ${colors.mediumGray};
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.fourteen};
    font-family: ${fontFamilies.roboto};
    text-transform: uppercase;
  }

  .subtitle {
    margin: ${uc('10px 0 18px')};
    color: ${colors.primary};
    font-size: ${fontSizes.seventeen};
    font-family: ${fontFamilies.proximaSoft};
    text-transform: none;
  }

  .image-wrapper {
    height: ${uc('120px')};
    padding: 0;
    background-color: ${colors.lightGray.eight};
  }

  .image {
    width: 100%;
    max-height: auto;
  }

  .container {
    height: ${uc('300px')};
  }
`;

const ResourceTile = ({ resource }) => {
  if (!resource) {
    return null;
  }
  const hasLogo = Boolean(resource.logoUrl);

  const truncatedSubtitle = truncateTextByWords(resource.title, 15);

  const subtitle =
    truncatedSubtitle.length > 80
      ? truncateTextByWords(truncatedSubtitle, 10)
      : truncatedSubtitle;

  return (
    <div
      data-testid={`resource-tile-${kebabCase(resource.resourceType)}`}
      css={wrapperCss}
    >
      <Link to={resource.slug}>
        <BaseTile
          customCss={customCss}
          title={pluralize.singular(resource.resourceType)}
          subtitle={subtitle}
          imageUrl={
            hasLogo
              ? sanityImage(resource.logoUrl)
                  .auto('format')
                  .size(300, 130)
                  .url()
              : '/svg/resources.svg'
          }
        />
      </Link>
    </div>
  );
};

ResourceTile.propTypes = {
  resource: PropTypes.instanceOf(Object).isRequired,
};

export default ResourceTile;
