import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import {
  colors,
  fontFamilies,
  unitConverter as uc,
} from '../../../styles/base';

const radioContainerCss = css`
  margin-bottom: 10px;
`;

const radioLabelCss = css`
  position: relative;
  display: block;
  padding-top: ${uc('5px')};
  padding-left: ${uc('24px')};
  font-family: ${fontFamilies.roboto};
  cursor: pointer;

  &:hover {
    border-color: ${colors.accent};
  }
`;

const radioCss = css`
  display: none;
  margin: 0;
  padding: 0;
`;

const SortOption = ({ isSelected, onSelect, sortOption }) => {
  const customRadioCss = css`
    position: absolute;
    top: ${uc('4px')};
    left: 0;
    width: ${uc('16px')};
    height: ${uc('16px')};
    background-color: ${isSelected ? colors.accent : colors.white};
    border: ${uc('2px')} solid ${colors.accent};
    border-radius: ${uc('8px')};

    &:checked {
      width: ${uc('16px')};
      height: ${uc('16px')};
      background-color: ${colors.accent};
      border-color: transparent;
    }
  `;

  return (
    <div css={radioContainerCss}>
      <label htmlFor={sortOption.name} css={radioLabelCss}>
        <input
          id={sortOption.name}
          css={radioCss}
          type="radio"
          checked={isSelected}
          onChange={() => {
            if (onSelect) {
              onSelect(sortOption);
            }
          }}
        />
        <span css={customRadioCss} />
        {sortOption.title}
      </label>
    </div>
  );
};

SortOption.propTypes = {
  sortOption: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SortOption;
