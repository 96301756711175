import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import SVG from 'react-inlinesvg';
import {
  unitConverter as uc,
  colors,
  fontSizes,
  fontWeights,
  fontFamilies,
} from '../../../styles/base';
import Text from '../../atoms/Text/Text';

const dropdownContainerCss = css`
  margin: ${uc('0 15px 5px')};
  background-color: ${colors.lightGray.one};
  border-radius: ${uc('10px')};
`;

const dropdownTitleCss = css`
  margin: 0;
  color: ${colors.mediumGray};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.seventeen};
  font-family: ${fontFamilies.proximaSoft};
`;

const dropdownToggleCss = css`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${uc('12px')};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const Dropdown = ({ children, defaultOpen, title }) => {
  const [open, setOpen] = useState(defaultOpen);
  const toggleOpen = () => setOpen(!open);

  const contentContainerDisplay = !open ? 'none' : 'block';

  const contentContainerCss = css`
    display: ${contentContainerDisplay};
    padding: ${uc('5px 15px')};
  `;

  const deg = open ? '180deg' : '0deg';

  const iconCss = css`
    transform: rotate(${deg});
    transition: transform 180ms ease-in;
    fill: ${colors.mediumGray};

    svg {
      width: ${uc(`30px`)};
      height: ${uc(`30px`)};
    }
  `;
  return (
    <div css={dropdownContainerCss}>
      <button type="button" css={dropdownToggleCss} onClick={toggleOpen}>
        <Text css={dropdownTitleCss}>{title}</Text>
        <span css={iconCss}>
          <SVG src="/svg/arrow_drop_down.svg" />
        </span>
      </button>
      <div css={contentContainerCss}>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
          return null;
        })}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element).isRequired,
  ]).isRequired,
};

Dropdown.defaultProps = {
  defaultOpen: false,
};

export default Dropdown;
