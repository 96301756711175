import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import {
  colors,
  fontFamilies,
  unitConverter as uc,
} from '../../../styles/base';

const checkboxContainerCss = css`
  margin-bottom: 10px;
`;

const checkboxLabelCss = css`
  position: relative;
  display: block;
  padding-top: ${uc('5px')};
  padding-left: ${uc('24px')};
  font-family: ${fontFamilies.roboto};
  cursor: pointer;

  &:hover {
    border-color: ${colors.accent};
  }
`;

const checkboxCss = css`
  display: none;
  margin: 0;
  padding: 0;
`;

const FilterItem = ({ filterItem, isSelected, onSelect }) => {
  const checkboxCustomCss = css`
    position: absolute;
    top: ${uc('4px')};
    left: 0;
    width: ${uc('16px')};
    height: ${uc('16px')};
    background-color: ${isSelected ? colors.accent : colors.white};
    border-radius: ${uc('5px')};

    &:checked {
      width: ${uc('16px')};
      height: ${uc('16px')};
      background-color: ${colors.accent};
    }
  `;

  return (
    <div css={checkboxContainerCss}>
      <label htmlFor={filterItem.name} css={checkboxLabelCss}>
        <input
          id={filterItem.name}
          css={checkboxCss}
          type="checkbox"
          checked={isSelected}
          onChange={() => {
            if (onSelect) {
              onSelect(filterItem);
            }
          }}
        />
        <span css={checkboxCustomCss} />
        {filterItem.title}
      </label>
    </div>
  );
};

FilterItem.propTypes = {
  filterItem: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FilterItem;
