import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import SVG from 'react-inlinesvg';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';
import Card from '../../atoms/Card/Card';
import { Heading4 } from '../../atoms/Headings/Headings';

const responsiveFilterMenuCss = css`
  display: block;
`;

const cardCss = css`
  position: relative;
  z-index: 15;
  align-self: baseline;
  padding-top: ${uc('5px')};
  padding-bottom: ${uc('5px')};
  overflow: hidden;
  border-radius: ${uc('25px')};
`;

const filterMenuCss = css`
  position: relative;
  display: block;

  @media (${breakpoints.tablet}) {
    display: none;
  }

  &.is-open {
    display: block;
  }
`;

const scrollContainerCss = css`
  display: block;
  max-height: 90vh;
  overflow-y: auto;

  @media (${breakpoints.tablet}) {
    max-height: 85vh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const titleCss = css`
  margin: ${uc('20px')};
`;

const FilterMenu = ({ children, closeTitle, openTitle, title }) => {
  const [open, setOpen] = useState(false);
  const cardClasses = open ? 'is-open' : '';
  const toggleButtonLabel = open ? closeTitle : openTitle;
  const arrowRotation = open ? '180deg' : '0';

  const openCloseButtonCss = css`
    position: relative;
    display: none;
    width: calc(100% - ${uc('32px')});
    margin: ${uc('15px 15px 8px')};
    padding: ${uc('12px')};
    color: ${colors.mediumGray};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.seventeen};
    font-family: ${fontFamilies.proximaSoft};
    text-align: left;
    background-color: ${colors.lightGray.one};
    border: none;
    border-radius: ${uc('10px')};
    outline: none;
    cursor: pointer;

    @media (${breakpoints.tablet}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `;

  const iconCss = css`
    transform: rotate(${arrowRotation});
    transition: transform 180ms ease-in;
    fill: ${colors.mediumGray};

    svg {
      width: ${uc(`30px`)};
      height: ${uc(`30px`)};
    }
  `;

  return (
    <div css={responsiveFilterMenuCss}>
      <Card css={cardCss}>
        <div css={filterMenuCss} className={cardClasses}>
          <div css={scrollContainerCss}>
            <Heading4 css={titleCss}>{title}</Heading4>
            {React.Children.map(children, child => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child);
              }
              return null;
            })}
          </div>
        </div>
        <button
          type="button"
          css={openCloseButtonCss}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {toggleButtonLabel}
          <span css={iconCss}>
            <SVG src="/svg/arrow_drop_down.svg" />
          </span>
        </button>
      </Card>
    </div>
  );
};

FilterMenu.propTypes = {
  children: PropTypes.node.isRequired,
  closeTitle: PropTypes.string.isRequired,
  openTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FilterMenu;
